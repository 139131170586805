const promise = require('es6-promise').Promise;

if (typeof Promise === 'undefined') {
  window.Promise = promise;
}

// Get element(s) by CSS selector:
function qs(selector, scope) {
  return (scope || document).querySelector(selector);
}

function qsa(selector, scope) {
  return (scope || document).querySelectorAll(selector);
}

// addEventListener wrapper:
function $on(target, type, callback) {
  target.addEventListener(type, callback);
}

function $onSelector(target, type, selector, callback) {
  target.addEventListener(type, (event) => {
    let ele = event.target;
    if (!ele.matches) {
      while (ele && !ele.msMatchesSelector(selector)) {
        ele = ele.parentElement;
      }
    } else {
      while (ele && !ele.matches(selector)) {
        ele = ele.parentElement;
      }
    }
    if (ele) {
      callback(event);
    }
  });
}

function $onSelectorElement(target, type, selector, callback) {
  const elements = target.getElementsByTagName(selector);
  for (let i = 0, lenList = elements.length; i < lenList; i += 1) {
    elements[i].addEventListener(type, (event) => {
      callback(event);
    });
  }
}

function $off(target, type, callback) {
  target.removeEventListener(type, callback);
}

function hasClass(target, className) {
  return target.classList.contains(className);
}

function addClass(target, className) {
  if (target !== null) {
    target.classList.add(className);
  }
}

function removeClass(target, className) {
  target.classList.remove(className);
}

function toggleClass(target, className) {
  target.classList.toggle(className);
}

function closest(target, className) {
  if (target === null) {
    return null;
  }

  if (hasClass(target, className)) {
    return target;
  }
  return closest(target.parentElement, className);
}

function log(...text) {
  console.log(...text); //eslint-disable-line
}

function forEach(elem, cb) {
  for (let index = 0, len = elem.length; index < len; index += 1) {
    cb(elem[index], index);
  }
}

function xhrRequest(method, url, data, contentType) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest(); // eslint-disable-line
    xhr.open(method, url);

    if (contentType) {
      xhr.setRequestHeader('Content-type', contentType);
    }

    if (data) {
      xhr.send(JSON.stringify(data));
    } else {
      xhr.setRequestHeader('If-Modified-Since', 'Sat, 1 Jan 2000 00:00:00 GMT');
      xhr.send();
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = () => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      });
    };
  });
}

function removeClassAll(nodeList, className) {
  Array.prototype.forEach.call(nodeList, (el) => {
    el.classList.remove(className);
  });
}

/**
   * Retrieves the current Y scroll position of the page.
   * @return {number} The vertical page offset in pixels.
*/
function getPageYOffset() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

// Production steps of ECMA-262, Edition 6, 22.1.2.1
if (!Array.from) {
  // eslint-disable-next-line
  Array.from = (function () {
    const toStr = Object.prototype.toString;
    // eslint-disable-next-line
    const isCallable = function (fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
    };
    // eslint-disable-next-line
    const toInteger = function (value) {
      const number = Number(value);
      if (isNaN(number)) { return 0; }
      if (number === 0 || !isFinite(number)) { return number; }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    // eslint-disable-next-line
    const maxSafeInteger = Math.pow(2, 53) - 1;
    // eslint-disable-next-line
    const toLength = function (value) {
      const len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    // The length property of the from method is 1.
    // eslint-disable-next-line
    return function from(arrayLike/*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      const C = this;

      // 2. Let items be ToObject(arrayLike).
      const items = Object(arrayLike);

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError('Array.from requires an array-like object - not null or undefined');
      }

      // 4. If mapfn is undefined, then let mapping be false.
      // eslint-disable-next-line
      const mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      let T;
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function');
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          // eslint-disable-next-line
          T = arguments[2];
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      const len = toLength(items.length);

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method
      // of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      const A = isCallable(C) ? Object(new C(len)) : new Array(len);

      // 16. Let k be 0.
      let k = 0;
      // 17. Repeat, while k < len… (also steps a - h)
      let kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len;
      // 20. Return A.
      return A;
    };
  }());
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) { //eslint-disable-line
    let index = position;
    const subjectString = this.toString();
    if (typeof index !== 'number' || !isFinite(index)
          || Math.floor(index) !== index || index > subjectString.length) {
      index = subjectString.length;
    }

    index -= searchString.length;
    const lastIndex = subjectString.indexOf(searchString, index);
    return lastIndex !== -1 && lastIndex === index;
  };
}

function debounce(fn, delay) {
  // from riot.js: https://github.com/rstacruz/simpler-debounce/blob/master/index.js
  let t;

  return function debounced() {
    clearTimeout(t);
    t = setTimeout(fn, delay);
  };
}

function pageName() {
  const path = window.location.pathname;
  const urlParts = path.split('/');
  if (urlParts.length > 0) {
    return unescape(urlParts.pop()).replace(/-/g, ' ').toLowerCase();
  }
  return 'homepage';
}
/**
 * detect the device based on screensize
 * @return {String} return the divice based on the screensize
 */
function deviceDetection() {
  const viewportWidth = window.innerWidth;
  if (viewportWidth < 768) {
    return 'mobile';
  } else if (viewportWidth <= 1024) {
    return 'tablet';
  }
  return 'desktop';
}

function validateEmail(email) {
  // eslint-disable-next-line
  return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));
}

function checkPersonalEmail(email, deniedDomains) {
  if (deniedDomains) {
    const array = deniedDomains.split(',');
    for (let i = 0; i < array.length; i += 1) {
      if (array[i].trim().length > 0 && email.indexOf(array[i]) !== -1) {
        return true;
      }
    }
  }

  return false;
}
/**
 * Update cookie based on the user actions
 * @param  {String} cookieName     Name of the cookie
 * @param  {String} cookieValue    Type of the cookie
 * @param  {Number} expeditionDays amount of days to be experid
 * @return {void}
 */
function updateCookie(cookieName, cookieValue, expeditionDays) { // eslint-disable-line
  const date = new Date();
  date.setTime(date.getTime() + (expeditionDays * 24 * 60 * 60 * 1000));
  const expires = date.toUTCString();
  document.cookie = `${cookieName} = ${cookieValue};expires=${expires};path=/`;
}

function getParameterByName(name) {
  const url = window.location.href;
  const trimmedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + trimmedName + '(=([^&#]*)|&|#|$)'); // eslint-disable-line
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export {
  qs,
  qsa,
  $on,
  $onSelector,
  $onSelectorElement,
  $off,
  hasClass,
  addClass,
  removeClass,
  toggleClass,
  log,
  closest,
  xhrRequest,
  forEach,
  removeClassAll,
  debounce,
  getPageYOffset,
  pageName,
  validateEmail,
  checkPersonalEmail,
  deviceDetection,
  updateCookie,
  getParameterByName,
};
