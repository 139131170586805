import { qsa } from './helpers';
import moduleList from './importModules';

/**
 * Init the global js functionality
 * @return {void}
 */
export default function main() {
  /**
   * Loading modules when a data-script attribute exist
   * @return {void}
   */
  function loadModule() {
    const modules = qsa('[data-script-funds]');
    for (let i = 0, len = modules.length; i < len; i += 1) {
      const elem = modules[i];
      const moduleName = elem.getAttribute('data-script-funds');
      const module = elem;

      if (!module.getAttribute('data-loaded')) {
        try {
          // loadModule
          moduleList[moduleName]().init(module);
          module.setAttribute('data-loaded', true);
        } catch (e) {
          console.log(moduleName, e); // eslint-disable-line
        }
      }
    }
  }

  /**
   * Init global js
   * @return {void}
   */
  function init() {
    loadModule();
  }

  return {
    init,
  };
}
main().init();
