import clickOutside from '../clickOutside';
import { qs, $on, toggleClass, removeClass } from '../helpers';

export default function footerTooltip() {
  const boxWidth = 153;
  const maxWidth = 767;
  const limit = 30;
  const arrowWidth = 3;
  const smallOver = 5;
  const main = qs('#page-main');
  let m;
  let triggerEL;
  let triggercloseEL;
  let toggleClassEl;
  let toggleEl;
  let boxEl;
  let arrowEl;

  // Only for click outside element
  function closeTooltip() {
    removeClass(toggleEl, toggleClassEl);
  }

  /**
   * Remove or add a class on an component
   * @param
   * @return {void}
   */
  function toggleClassModule() {
    toggleClass(toggleEl, toggleClassEl);
  }

  /**
   * Locate the arrow
   * @param {x} position
   * @return {void}
   */
  function positionArrow(position) {
    arrowEl.style.left = `${position - 2}px`;
    }
  function closeOnResizeWindow() {
      var elWithClass = qs('.tooltip-toggle-class', main);
      if (elWithClass !== undefined && elWithClass !== null) {
          removeClass(elWithClass, toggleClassEl);
      }
  }

  /**
   * math to locate the box tooltip
   * @param
   * @return {void}
   */
  function positionBox() {
    const screen = window.innerWidth;
    const xPosition = triggerEL.offsetLeft - triggerEL.scrollLeft;

    /* different behavior for mobile and tablet, computer */
    if (screen > maxWidth) {
      /* if the tooltip goes out of the limits */
      if (((xPosition + boxWidth) > (screen - limit)) || (xPosition - boxWidth) < limit) {
        /* fix to the right or left limits */
        if (xPosition > (boxWidth + limit)) {
          const position = screen - limit - (boxWidth * 2);
          boxEl.style.left = `${position}px`;
          positionArrow((xPosition - position) + arrowWidth);
        } else {
          boxEl.style.left = `${limit}px`;
          positionArrow((xPosition + arrowWidth) - limit);
        }
      } else {
        const position = xPosition - boxWidth;
        boxEl.style.left = `${position}px`;
      }
    } else {
      positionArrow((xPosition + arrowWidth + smallOver) - limit);
    }
  }

  /**
   * Init module
   * @param  {html} module recieve an html markup node
   * @return {void}
   */
  function init(module) {
    m = module;
    toggleClassEl = 'tooltip-toggle-class';
    toggleEl = qs('.article-page__footnote__tooltip', m);
    triggerEL = qs('.article-page__footnote__link', m);
    triggercloseEL = qs('.article-page__footnote__closelink', m);
    boxEl = qs('.article-page__footnote__tooltip', m);
    arrowEl = qs('.arrow', m);


    // add eventlistener
    $on(triggerEL, 'click', positionBox);
    $on(triggerEL, 'click', toggleClassModule);
    $on(triggercloseEL, 'click', toggleClassModule);
    window.onresize = closeOnResizeWindow;
    clickOutside(m, closeTooltip);
  }

  return {
    init,
  };
}
