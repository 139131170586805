import { qs } from '../helpers';

/**
 * clickOutside
 * @param {Object} element to be used
 * @param {Callback} callback to be triggered
 * @return {void}
 */

export default function clickOutside(element, callback) {
  const main = qs('#page-main');

  // Click event on body
  main.addEventListener('click', (e) => {
    const isInside = element.contains(e.target);

    // Check if click was inside the element
    if (!isInside) {
      callback();
    }
  });
}
