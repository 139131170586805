import { qs, $on } from '../helpers';

export default function audioPlayer() {
  // let players = [];
  let listenerBoxSizePx;
  const PAUSE_PLAY_BUTTON = 'audio_pause_play_button';
  const CHANGE_VOL = 'audio_change_vol';
  const AUDIO_PLAYER = 'audio_player';
  const DURATION = 'audio_duration';
  const AUDIO_CURRENT_TIME = 'audio_current_time';
  const AUDIO_PERCENT_LISTENED = 'audio_percent_listened';
  const AUDIO_VOL_IMG = 'audio_vol_img';
  const AQR_AUDIO_PLAYER_CLASS = 'aqr-audio-player';
  const AQR_AUDIO_PER_LIST_CONTAINER = 'audio-player_percent-listened-container';
  const AUDIO_PLAYER_TRANSCRIPT = 'audio_player_transcript';
  const TRANSCRIPT_IS_DISPLAYED_ATTR = 'transcript-is-displayed';
  const AUDIO_SHOW_HIDE_LINK = 'audio_player_show_hide_link';
  const AUDIO_PLAYER_TRANSCRIPT_ARROW = 'audio_player_transcript_arrow';

  function calculateTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }

  function initializePlayer(player, module) {
    // get the index ID of the player, should be in format player.id
    // this will be used to identify other elements(range, volumne, play / pause)
    const playerId = player.id.split('---')[1];
    let percentListened;
    let pixels;

    // make sure the change volume slider is not displayed
    qs(`#${CHANGE_VOL}---${playerId}`, module).style.display = 'none';

    // default volume input to 1 as firefox seems to save last setting
    qs(`#${CHANGE_VOL}---${playerId}`, module).value = 1;

    // Add event listener for play pause button
    $on(qs(`#${PAUSE_PLAY_BUTTON}---${playerId}`, module), 'click', (event) => {
      if (!player.paused) {
        player.pause();
        event.target.setAttribute('src', '/assets/img/play_aqr.png');
      } else {
        player.play();
        event.target.setAttribute('src', '/assets/img/pause_aqr.png');
      }
    });

    // add event listener for volume click icon
    $on(qs(`#${AUDIO_VOL_IMG}---${playerId}`, module), 'click', () => {
      const box = qs(`#${CHANGE_VOL}---${playerId}`, module);
      if (box.style.display === 'none') {
        box.style.display = 'block';
      } else {
        box.style.display = 'none';
      }
    });

    // add event listener to detect change in volume
    $on(qs(`#${CHANGE_VOL}---${playerId}`, module), 'change', (event) => {
      player.volume = event.target.value; // eslint-disable-line no-param-reassign
    });

    // add event listener for time updates
    // if the audio has completed then update button to play icon
    $on(qs(`#${AUDIO_PLAYER}---${playerId}`, module), 'timeupdate', (event) => {
      const el = event.target;
      percentListened = el.currentTime / el.duration;
      pixels = percentListened * listenerBoxSizePx;
      qs(`#${AUDIO_CURRENT_TIME}---${playerId}`, module).textContent = calculateTime(el.currentTime);
      qs(`#${AUDIO_PERCENT_LISTENED}---${playerId}`, module).style.width = `${Math.floor(pixels)}px`;
      if (el.currentTime === el.duration) {
        qs(`#${PAUSE_PLAY_BUTTON}---${playerId}`, module).setAttribute('src', '/assets/img/play_aqr.png');
      }
    });

    // add an event listener for the show transcript
    if (qs(`#${AUDIO_SHOW_HIDE_LINK}---${playerId}`, module)) {
      $on(qs(`#${AUDIO_SHOW_HIDE_LINK}---${playerId}`, module), 'click', (event) => {
        const el = event.target;
        const transcriptIsDisplayed = el.getAttribute(TRANSCRIPT_IS_DISPLAYED_ATTR) === 'true';
        if (transcriptIsDisplayed) {
          qs(`#${AUDIO_PLAYER_TRANSCRIPT}---${playerId}`, module).style.display = 'none';
          qs(`#${AUDIO_PLAYER_TRANSCRIPT_ARROW}---${playerId}`, module).style.transform = 'rotate(90deg)';
          el.setAttribute(TRANSCRIPT_IS_DISPLAYED_ATTR, 'false');
        } else {
          qs(`#${AUDIO_PLAYER_TRANSCRIPT}---${playerId}`, module).style.display = 'block';
          qs(`#${AUDIO_PLAYER_TRANSCRIPT_ARROW}---${playerId}`, module).style.transform = 'rotate(270deg)';
          el.setAttribute(TRANSCRIPT_IS_DISPLAYED_ATTR, 'true');
        }
      });
    }

    // get and set the duration of the loaded file
    // if ready state > 0 then audio component is loaded and ready to play so you can get duration
    // else you need to wait for metadata to be loaded to get duration
    if (qs(`#${AUDIO_PLAYER}---${playerId}`, module).readyState > 0) {
      qs(`#${DURATION}---${playerId}`, module).textContent = calculateTime(qs(`#${AUDIO_PLAYER}---${playerId}`, module).duration);
    } else {
      qs(`#${AUDIO_PLAYER}---${playerId}`, module).addEventListener('loadedmetadata', (event) => {
        qs(`#${DURATION}---${playerId}`, module).textContent = calculateTime(event.target.duration);
      });
    }
  }

  function init(module) {
    const player = qs(`.${AQR_AUDIO_PLAYER_CLASS}`, module);
    if (player) {
      listenerBoxSizePx = qs(`.${AQR_AUDIO_PER_LIST_CONTAINER}`, module).offsetWidth;
      // for some reason listener box size is not always calculated correctly
      if (listenerBoxSizePx > 200) {
        listenerBoxSizePx = 150;
      }
    }
    initializePlayer(player, module);
  }

  return {
    init,
  };
}
